import { NProgress } from '@tanem/react-nprogress';

export const PageProgress = ({
  isRouteChanging,
  loadingKey,
}: {
  isRouteChanging: boolean;
  loadingKey?: string;
}) => {
  return (
    <NProgress isAnimating={isRouteChanging} key={loadingKey} animationDuration={700}>
      {({ isFinished, progress, animationDuration }) => (
        <div
          style={{
            pointerEvents: 'none',
            position: 'fixed',
            width: '100%',
            height: '2px',
            left: 0,
            top: 0,
            zIndex: 999999,
            opacity: isFinished ? 0 : 1,
            transition: `opacity ${animationDuration}ms linear`,
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              background: '#c9e9f5',
              transform: `translateX(${(-1 + progress) * 100}%)`,
              transition: `transform ${animationDuration}ms linear`,
            }}
          >
            <div
              style={{
                position: 'absolute',
                width: '100px',
                height: '100%',
                right: 0,
                transform: 'rotate(3deg) translate(0px, -4px)',
                boxShadow: '0 0 8px #30b3e4',
              }}
            />
          </div>
        </div>
      )}
    </NProgress>
  );
};

export default PageProgress;
