import { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';
import { useMount } from 'react-use';

import { useStore } from '@/store';
import Spinner from '@/components/spinner';

interface AuthGuardProps {
  children: ReactNode;
  init?: boolean;
}

const AuthGuard: React.FC<AuthGuardProps> = observer((props) => {
  const router = useRouter();
  const store = useStore();

  // Bypass auth guard when accessing cutup
  if (router.asPath.startsWith('/cutup')) {
    return <div>{props.children}</div>;
  }

  useMount(() => {
    if (props.init) {
      store.init();
    }
  });

  useEffect(() => {
    // Bail out if the current route is cutup
    if (router.asPath.startsWith('/cutup')) {
      return;
    }

    // Redirect to login page with `redirect_to` query
    const isAuthRoute = router.asPath.startsWith('/auth/');
    if (store.isLoggedOut && !isAuthRoute) {
      router.push(
        '/auth/login' +
          (router.asPath !== '/' ? '?redirect_to=' + encodeURIComponent(router.asPath) : '')
      );
      return;
    }

    if (store.isLoggedIn) {
      if (!store.user?.registrationComplete && !router.asPath.startsWith('/register')) {
        router.push('/register');
        return;
      }

      // Redirect to quiz if not completed
      if (!store.quiz.latest?.complete && !router.asPath.startsWith('/quiz')) {
        router.push('/quiz');
        return;
      }

      // Redirect to `redirect_to` query or dashboard
      if (isAuthRoute) {
        let redirectTo;
        if (
          router.query.redirect_to &&
          (!Array.isArray(router.query.redirect_to) || router.query.redirect_to[0])
        ) {
          redirectTo = Array.isArray(router.query.redirect_to)
            ? router.query.redirect_to[0]
            : router.query.redirect_to;
        }

        if (redirectTo && !redirectTo.startsWith('/auth')) {
          router.push(redirectTo);
          return;
        }

        router.push('/');
        return;
      }
    }
  }, [store.isLoggedIn, store.isLoggedOut]);

  // TODO: improve flash of dashboard when redirecting to quiz
  if (
    store.isLoggedIn ||
    ((store.isLoggedOut || store.isLoggingIn) && router.asPath.startsWith('/auth/'))
  ) {
    return <div>{props.children}</div>;
  }

  return (
    <div className="u-flex u-justify-center u-items-center" style={{ height: '100vh' }}>
      <Spinner />
    </div>
  );
});

export default AuthGuard;
