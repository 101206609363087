import { SVGAttributes } from 'react';
import { motion } from 'framer-motion';

export const Spinner: React.FC<{ small?: boolean; width?: number; height?: number }> = ({
  small,
  width,
  height,
}) => {
  const LoadingPath = (
    props: Omit<
      SVGAttributes<SVGPathElement>,
      'onAnimationStart' | 'onDragStart' | 'onDragEnd' | 'onDrag'
    >
  ) => (
    <motion.path
      {...props}
      initial={{
        strokeDasharray: 400,
        strokeDashoffset: 500,
        stroke: '#fff',
        opacity: 0.3,
      }}
      animate={{
        opacity: [0.3, 1, 1, 1, 0],
        strokeDashoffset: [500, 500, 0, 0, 0],
        stroke: ['#fff', '#fff', '#c5c5c5', '#fff', '#fff'],
      }}
      transition={{ times: [0, 0.1, 0.7, 0.8, 1], loop: Infinity, duration: 2, type: 'keyframes' }}
    />
  );

  if (small) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 34 31"
        width={width || 16}
        height={height || 16}
      >
        <LoadingPath
          d="M34.738539,17.045778 L21.7445224,39.4232642 C21.1898585,40.3784718 21.5145634,41.6024646 22.469771,42.1571285 C22.7748315,42.3342691 23.1213174,42.427572 23.4740789,42.427572 L48.8914822,42.427572 C49.9960517,42.427572 50.8914822,41.5321415 50.8914822,40.427572 C50.8914822,40.0878814 50.8049619,39.753788 50.6400803,39.4567968 L38.2166936,17.0793106 C37.6805493,16.1135865 36.4630443,15.7653434 35.4973203,16.3014878 C35.1818917,16.4766053 34.9197055,16.7337844 34.738539,17.045778 Z"
          transform="translate(-19 -14)"
          strokeWidth="4"
          fill="none"
          fillRule="evenodd"
        />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 73"
      width={width || 250}
      height={height || 250}
    >
      <g fill="none" fillRule="nonzero">
        <LoadingPath d="M75.8163 63.9292L42.9716 6.6848A1.3713 1.3713 0 0041.7857 6c-.4884 0-.94.2608-1.1859.6848L11.1866 57.3602a1.3855 1.3855 0 00-.004 1.3817 1.3714 1.3714 0 001.1899.6922h36.1502c.7581 0 1.3727-.6176 1.3727-1.3795 0-.7619-.6146-1.3795-1.3727-1.3795H14.7535l27.0229-46.565 31.6589 55.1986A1.3723 1.3723 0 0074.6249 66c.491 0 .9448-.2626 1.1905-.6897a1.387 1.387 0 00.001-1.3811z" />
        <LoadingPath d="M67.7998 63.894L42.4572 19.6833A1.3592 1.3592 0 0041.2794 19c-.4857 0-.9346.2604-1.1778.6832L22.1823 50.8868a1.3748 1.3748 0 00.0004 1.369c.2435.4234.6933.684 1.1797.6833h20.9969c.7515 0 1.3606-.6126 1.3606-1.3682 0-.7557-.6091-1.3682-1.3606-1.3682H25.7227L41.277 23.113l24.167 42.1725a1.3597 1.3597 0 001.184.7145 1.3594 1.3594 0 001.1955-.6945 1.3748 1.3748 0 00-.0238-1.3882v-.0232z" />
        <LoadingPath d="M37.1766.7132L.1746 63.9698a1.351 1.351 0 00.014 1.3528c.2462.4168.697.6743 1.1857.6774h59.2454c.4937-.001.9493-.2617 1.1957-.6842a1.351 1.351 0 00-.0007-1.3646L43.603 32.8154c-.3917-.6317-1.222-.84-1.8714-.4695-.6496.3705-.8817 1.1847-.5233 1.8353L58.2251 63.287H3.769L39.5712 2.079a1.3512 1.3512 0 00.0243-1.3858C39.348.2613 38.8826-.0041 38.38 0c-.5026.0042-.9634.2772-1.2035.7132z" />
      </g>
    </svg>
  );
};

export default Spinner;
