import 'mobx-react/batchingForReactDom';
import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import * as Sentry from '@sentry/node';

import { loadGTMScript, pageview } from '@/utils/gtm';
import { Provider, store } from '@/store';
import AuthGuard from '@/components/auth/auth-guard';

import '@/sass/main.scss';
import PageProgress from '@/components/page-progress';

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
});

interface MyAppState {
  isRouteChanging: boolean;
  loadingKey?: string;
}

export default class MyApp extends App<{ err: any }, {}, MyAppState> {
  state: MyAppState = {
    isRouteChanging: false,
    loadingKey: undefined,
  };

  handleRouteChangeStart = (path: string) => {
    this.setState({
      isRouteChanging: true,
      loadingKey: path,
    });
  };

  handleRouteChangeComplete = (path: string) => {
    pageview({ path });

    this.setState({
      isRouteChanging: false,
    });
  };

  handleRouteChangeError = () => {
    this.setState({
      isRouteChanging: false,
    });
  };

  componentDidMount() {
    loadGTMScript();

    Router.events.on('routeChangeStart', this.handleRouteChangeStart);
    Router.events.on('routeChangeComplete', this.handleRouteChangeComplete);
    Router.events.on('routeChangeError', this.handleRouteChangeError);
  }

  componentWillUnmount() {
    Router.events.off('routeChangeStart', this.handleRouteChangeStart);
    Router.events.off('routeChangeComplete', this.handleRouteChangeComplete);
    Router.events.off('routeChangeError', this.handleRouteChangeError);
  }

  render() {
    const { Component, pageProps, __N_SSG, err } = this.props;
    const { isRouteChanging, loadingKey } = this.state;

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
          />
        </Head>
        <Provider value={store}>
          <PageProgress isRouteChanging={isRouteChanging} loadingKey={loadingKey} />
          <AuthGuard init={!__N_SSG}>
            <Component {...pageProps} err={err} />
          </AuthGuard>
        </Provider>
      </>
    );
  }
}
