let isLoaded = false;
let isLoading = false;

export const loadGTMScript = (): void => {
  // Check if the script is already loaded or is in process of loading
  if (isLoaded || isLoading) {
    return;
  }

  // Bail out if GTM container ID is not defined
  if (!process.env.NEXT_PUBLIC_GTM_CONTAINER_ID) {
    console.warn('GTM container ID is not defined.');
    return;
  }

  isLoading = true;

  (window as any).dataLayer = (window as any).dataLayer || [];

  (window as any).dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });

  const gtmScript = document.createElement('script');
  const scriptUrl =
    'https://www.googletagmanager.com/gtm.js?id=' + process.env.NEXT_PUBLIC_GTM_CONTAINER_ID;

  gtmScript.setAttribute('src', scriptUrl);
  gtmScript.setAttribute('type', 'text/javascript');
  gtmScript.setAttribute('async', 'true');

  gtmScript.onload = () => {
    isLoaded = true;
    isLoading = false;
  };

  gtmScript.onerror = () => {
    gtmScript.remove();
    isLoaded = false;
    isLoading = false;
  };

  document.body.appendChild(gtmScript);
};

export const pageview = (page: { path?: string; title?: string } = {}) => {
  if (!(window as any).dataLayer) {
    (window as any).dataLayer = [];
  }

  const { path = window.location.pathname, title = document.title } = page;

  (window as any).dataLayer.push({
    event: 'pageview',
    page: {
      path,
      title,
    },
  });
};
